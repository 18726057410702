<template>
  <div>
    <Loading v-show="show" />

    <div class="breadcrumb-banner-area">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <div class="breadcrumb-text">
              <h1 class="text-left">{{ Magazine }}</h1>
              <div class="breadcrumb-bar">
                <ul class="breadcrumb text-left">
                  <li>
                    <router-link href="#" aria-label="title" to="/">{{
                      home
                    }}</router-link>
                  </li>
                  <li>{{ Magazine }}</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="latest-area section-padding" style="padding: 50px 0 60px">
      <div class="container">
        <div class="row">
          <div
            v-for="event in Events"
            :key="event.postId"
            class="col-lg-4 col-md-6 col-12 events_top"
          >
            <div class="single-event-item">
              <div class="single-event-image">
                <a>
                  <img
                    loading="lazy"
                    alt=""
                    v-bind:src="
                      'https://api2.yuniv.net:444/images/post/' +
                      event.postImage
                    "
                  />
                </a>
              </div>
              <div class="single-event-text">
                <h6>
                  <a
                    aria-label="title"
                    style="line-height: 2; text-align: justify"
                    >{{ event.postTitle }}</a
                  >
                </h6>
                <p style="text-align: justify">{{ event.postSubTitle }}</p>
                <router-link
                  href="#"
                  aria-label="title"
                  class="button-default"
                  :to="{
                    name: 'issue-with-BrnId-And-Type-And-id',
                    params: {
                      type: 'Univ.Research.issue',
                      id: event.postID,
                      BrnId: event.brnID,
                    },
                  }"
                  >{{ Detials }}</router-link
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Loading from "./Loading";

import axios from "axios";
import ArJson from "../../public/i18n/Ar.json";
import EnJson from "../../public/i18n/En.json";

export default {
  components: {
    Loading,
  },
  data() {
    return {
      Events: [],
      show: true,
      ArTranslat: ArJson,
      EnTranslat: EnJson,
      translate: "",
      home: "",
      Magazine: "",
      Detials: "",
    };
  },
  mounted() {
    window.scroll(0, 0);
    var self = this;
    if (localStorage.getItem("lang") == "Ar") {
      document.getElementById("langfilewebsites")?.remove();
      self.translate = self.ArTranslat;
    } else {
      let link1 = document.createElement("link");
      link1.setAttribute("rel", "stylesheet");
      link1.id = "langfilewebsites";
      link1.setAttribute("href", "../../../style-ltr.css");
      document.head.appendChild(link1);
      self.translate = self.EnTranslat;
    }
    self.home = self.translate[0]["HeaderAndFooter"]["Home"];
    self.Magazine = self.translate[0]["Home"]["Magazine"];
    self.Detials = self.translate[0]["Home"]["Detials"];
    self.getApis();
  },
  methods: {
    getApis() {
      var bodyFormData1 = new FormData();
      var self = this;
      bodyFormData1.append("check", "getPost");
      bodyFormData1.append("Lang", localStorage.getItem("lang"));
      bodyFormData1.append("FbrnId", this.$route.params.BrnId);
      bodyFormData1.append("Type", this.$route.params.type);
      bodyFormData1.append("ParentId", "NTROOT0");
      bodyFormData1.append("PostName", "");
      bodyFormData1.append("Pno", "-1");
      axios({
        method: "post",
        url: "https://api2.yuniv.net:444/our_team/getPost",
        data: bodyFormData1,
      })
        .then(function (response) {
          self.Events = response.data;
          document.getElementById("VueMainJsNewTouch")?.remove();
          let recaptchaScript = document.createElement("script");
          recaptchaScript.setAttribute("src", "/js/main.js");
          recaptchaScript.id = "VueMainJsNewTouch";
          document.head.appendChild(recaptchaScript);

          setTimeout(() => {
            self.show = false;
            document.querySelectorAll("div.loader").forEach(function (element) {
              element.setAttribute("hidden", true);
            });
          }, 100);
        })
        .catch(function (response) {
          // eslint-disable-next-line no-console
          console.log("error", response);
        });
    },
  },
};
</script>

<style scoped>
.events_top {
  margin-top: 50px;
}
</style>
